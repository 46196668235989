import "./App.css";
import "monday-ui-react-core/dist/main.css"

import { Route, HashRouter as Router, Routes } from 'react-router-dom';

import { AppProvider } from "./store/app";
import Landing from './pages/Landing'
//Explore more Monday React Components here: https://style.monday.com/
import React from "react";
import Unauthorized from './pages/Unauthorized'

class App extends React.Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      settings: {},
      name: "",
    };
  }

  componentDidMount() {
    // TODO: set up event listeners
  }

  render() {
    return <div className="App">
      <AppProvider value={{}}>
        <Router>
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/unauthorized' element={<Unauthorized />} />
          </Routes>
        </Router>
      </AppProvider>
    </div>;
  }
}

export default App;
