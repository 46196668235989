import React, { useReducer } from "react";

const INITIAL_STATE = {
};

const reducer = (state, action) => ({ ...state, ...action });

const AppContext = React.createContext([]);

export const AppProvider = ({ children, value }) => (
  <AppContext.Provider
    value={useReducer(reducer, { ...INITIAL_STATE, ...value })}
  >
    {children}
  </AppContext.Provider>
);

export const AppConsumer = AppContext.Consumer;

export default AppContext;
