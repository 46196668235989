import "monday-ui-react-core/dist/main.css";
import "./loading.css";

import { AttentionBox } from "monday-ui-react-core"
import React from "react";

export default function Unauthorized() {

    return (
        <div className="App">
            <div className="App flex center">
                <AttentionBox
                    title="Unauthorized"
                    text=""
                    type="danger"
                />
            </div>
            <br />
        </div>
    );
}
