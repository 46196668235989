import "./landing.css";

import { Box, Button, Flex, Icon } from 'monday-ui-react-core'
import React, { useEffect, useState } from "react";

import { ROUTES } from "../../constants";
import axios from "axios";
import backIcon from "../../assets/icons/back.svg"
import { deleteAccount as deleteAccountAPI } from "../../services/api"
import { generateToken } from '../../services/api'
import jwt from "jsonwebtoken";
import logo from "../../assets/logo.png"
import trashIcon from "../../assets/icons/trash.svg"
import { useNavigate } from "react-router-dom";

const TRACKING_ID = 'G-F6H6MWGPPB'
export default function Landing() {
    let navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("token")

    const [authorized, setAuthorized] = useState(false)
    const [usedAccounts, setUsedAccounts] = useState(0)
    const [totalAccounts, setTotalAccounts] = useState(0)
    const [users, setUsers] = useState([])
    const [allowAdd, setAllowAdd] = useState(false)

    let accountId, userId, backToUrl, appKind, entityId, automation_id

    const monday_auth_url = `${process.env.REACT_APP_MONDAY_AUTH_URL}?client_id=${process.env.REACT_APP_MONDAY_CLIENT}&state=${token}`;

    try {
        const data = jwt.verify(token, process.env.REACT_APP_MONDAY_SECRET);
        accountId = data.accountId
        userId = data.userId
        backToUrl = data.backToUrl
        appKind = data.appKind
        entityId = data.entityId

        const backToUrlSplit = backToUrl.split('/')
        automation_id = backToUrlSplit[backToUrlSplit.length - 1]

    }
    catch {
        navigate(`/${ROUTES.UNAUTHORIZED}`);
    }

    const storeWebhook = async (account_id, monday_user_id, pb_user_id) => {
        const token = await generateToken({
            account_id,
            monday_user_id,
            pb_user_id,
            automation_id
        })
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/webhooks/placeholder`,
            headers: {
                Authorization: token
            }
        })
            .then(() => { })
            .catch((err) => {
                console.log("Err: ", err);
            });

    }

    const storeView = async (account_id, pb_user_id, view_id) => {
        const token = await generateToken({
            account_id,
            pb_user_id,
            view_id
        })
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/views`,
            headers: {
                Authorization: token
            }
        })
            .catch((err) => {
                console.log("Err: ", err);
            });

    }

    const checkApp = async (account_id, pb_user_id, entity_id) => {
        if (appKind === '_board-view_') {
            await storeView(account_id, pb_user_id, entity_id);
        } else if (appKind === '_item-view_') {
            await storeView(account_id, pb_user_id, entity_id);
        } else {
            await storeWebhook(account_id, userId, pb_user_id);
        }
        window.open(backToUrl, '_self')
    }

    const onClickDelete = async (accountid, userid, pb_user_id, email) => {
        const response = await deleteAccountAPI(accountid, userid, pb_user_id, email)
        console.log(response)
        window.location.reload()
    }

    const onClickBack = async () => {
        window.open(backToUrl, '_self')
    }

    const onClickAddAccount = async () => {
        window.open(monday_auth_url, '_self')
    }

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/accounts`,
                headers: {
                    Authorization: token
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                });
            setUsedAccounts(response.data.usedAccounts ?? 0)
            setTotalAccounts(response.data.totalAccounts ?? 0)
            setUsers(response.data.accounts)

            if (response.data.usedAccounts < response.data.totalAccounts) {
                setAllowAdd(true)
            } else {
                setAllowAdd(false)
            }
        };

        fetchUsers();
    }, []);


    return (
        <>
            <div className="background"></div>
            <div className="foreground">
                <Flex gap={Flex.gaps.MEDIUM} direction={Flex.directions.COLUMN} className="outer-container">
                    <Box>
                        <Flex gap={Flex.gaps.MEDIUM} direction={Flex.directions.COLUMN} className="inner-container">
                            <div className="back-icon">
                                <Icon icon={backIcon} iconType={Icon.type.SRC} onClick={onClickBack} />
                            </div>

                            <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.MEDIUM} justifyContent="center" className="flex-summary" justify={Flex.justify.SPACE_BETWEEN} >
                                <div>
                                    <img src={logo} className="logo" />
                                </div>
                                {(allowAdd ?
                                    <div className="add-account-title">
                                        Add Account
                                    </div>
                                    : <div className="div-plan-exceeded">
                                        <Box className="box-plan-exceeded">
                                            <div className="title-plan-exceeded">
                                                Plan Exceeded
                                            </div>
                                            <div className="subtitle-plan-exceeded">
                                                You have reached your account limit. To add more accounts you can <strong>Upgrade</strong> your plan or remove an account.
                                            </div>
                                        </Box>

                                    </div>
                                )}

                                <Flex justify={Flex.justify.SPACE_BETWEEN} className="flex-account-used">
                                    <div className="account-used-title">
                                        Accounts Used
                                    </div>
                                    <div className="account-used-number">
                                        {usedAccounts} / {totalAccounts}
                                    </div>
                                </Flex>
                                <div className="account-used-subtitle">
                                    Total accounts used across <strong>monday.com</strong>
                                </div>
                                <div className="choose-title">
                                    Choose an existing Account
                                </div>

                                <div className="div-account-list">
                                    {users.map(users => {
                                        const { pb_email, pb_user_id } = users;
                                        return (<>
                                            <Flex className="flex-account-list" justify={Flex.justify.SPACE_BETWEEN}>
                                                <Button href={backToUrl} onClick={(() => { checkApp(accountId, pb_user_id, entityId) })} kind={Button.kinds.SECONDARY} className="account-email-button">
                                                    {pb_email}
                                                </Button>
                                                <Box className="account-delete-box">
                                                    <Icon icon={trashIcon} iconType={Icon.type.SRC} onClick={(() => { onClickDelete(accountId, userId, pb_user_id, pb_email) })} className="account-delete-img" />
                                                </Box>
                                            </Flex>
                                        </>
                                        )
                                    })}
                                </div>

                                <Flex style={{ "width": "100%" }} direction={Flex.directions.COLUMN} justifyContent="center" gap={Flex.gaps.LARGE}>
                                    <Button
                                        className="add-account-btn"
                                        onClick={onClickAddAccount}
                                        disabled={!allowAdd}
                                    >
                                        Add Account
                                    </Button>
                                    <div><a href="https://www.support.carbonweb.co/?utm_source=pb-app-login" target="_blank" rel="noopener noreferrer" className="need-help">Need Help?</a></div>
                                    <br />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Box>
                </Flex>
            </div >
        </>
    );
}

