import axios from "axios";
import jwt from "jsonwebtoken";

let api = null;
let user = null;
let context = null;

export async function generateToken(data) {
  const token = jwt.sign(data, process.env.REACT_APP_MONDAY_SECRET
  );
  return token
}

export async function createApi(data = null) {
  const token = await generateToken(data)
  if (!api) {
    api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    
    api.defaults.headers.common.Authorization = token;
    
    return api;
  
  } else {
    api.defaults.headers.common.Authorization = token;
  
    return api;
  }
}

export async function checkTokens() {
  const carbonApi = await createApi();
  const response = await carbonApi.get("tokens/check").then().catch();
  return response.data.data;
}

export async function getDialSessionCallback() {
  const carbonApi = await createApi();
  const response = await carbonApi.get(`webhooks/master`);
  return response.data.data;
}

export async function getPhoneBurnerToken() {
  const carbonApi = await createApi();
  const response = await carbonApi.get(`tokens/phoneburner`);
  return response.data.data.token;
}

export async function getPhoneBurnerDetails() {
  const carbonApi = await createApi();
  const response = await carbonApi.get(`accounts/phoneburner`);
  console.log(response)
  return response.data.data;
}

export async function logout() {
  const carbonApi = await createApi();
  const response = await carbonApi.delete(`views`);
  return response.data.data;
}

export async function deleteAccount(accountId, userId, pbUserId, pbemail) {
  const carbonApi = await createApi({ accountId, userId, pbUserId, pbemail });
  const response = await carbonApi.delete("accounts").then().catch();
  return response.data;
}

export default api;